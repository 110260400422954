import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import styles from './ButtonGroup.module.scss';

function ButtonsGroup({
  options, labels, setCurrentTab, className,
}) {
  const [size, setSize] = useState(options[0]);

  useEffect(() => {
    setCurrentTab(size);
  }, [size]);

  return (
    <Radio.Group
      value={size}
      onChange={(e) => setSize(e.target.value)}
      className={`${styles.wrapper} ${className}`}
      optionType="button"
      buttonStyle="solid"
    >
      {options.map((option, index) => <Radio.Button value={option}>{labels[index]}</Radio.Button>)}
    </Radio.Group>
  );
}

export default ButtonsGroup;
