import React, { useCallback, useEffect, useState } from 'react';
import styles from '../Marketing/index.module.scss';
import { isAuthGuard } from '../../guards/isAuthGuard';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import UsersService from '../../services/usersService';
import StorageService from '../../services/storage.service';
import { dayInMilliSeconds } from '../../constans/datepicker';
import { STORAGE_DESTINATION } from '../DestinationTree';
import { getShortDestinationName } from '../../helpers/helper-methods';
import SearchInput from '../Search';
import SearchFilters from '../SearchFilters/SearchFilters';
import SimpleSearchResultCard from '../SimpleSearchResultCard';
import { free } from '../../constans/activity-review';
import Pagination from '../Pagination';
import useDebounce from '../../hooks/useDebounce';
import {
  PROPERTY_CATEGORY_FILTER_INITIAL_VALUES,
  PROPERTY_CATEGORY_OPTIONS,
} from '../../constans/property';
import { cardClick, getIds, getLocationName } from '../../helpers/generateLinks';
import { activityGetter, getter, paginationLimit } from '../../helpers/getter';
import ItinerariesTab from '../Home/ItinerariesTab/ItinerariesTab';
import ButtonsGroup from '../ButtonGroup/ButtonGroup';
import { ACTIVITY_CATEGORY_FILTER_INITIAL_VALUES, ACTIVITY_CATEGORY_OPTIONS } from '../../constans/activity';
import { VideoEmulatorLaptop, VideoEmulatorMobile } from '../VideoImulator/VideoEmulator';

const storageService = StorageService();
const getUser = () => storageService.get(LOCALSTORAGE_KEYS.USER, {});
const setTab = (tabId) => storageService.set(LOCALSTORAGE_KEYS.HOME_TAB, tabId);

const options = ['activity', 'property'];

const desktopVideoLink = 'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_30a7e856-9484-45c5-aa3a-7de5835cbd06.mp4';
const mobileVideoLink = 'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_c3a2677b-0d21-4641-9186-a4abfc4bfdca.mp4';

function Home({ destination }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [propertiesFilters, setPropertiesFilters] = useState({});
  const [activitiesFilters, setActivitiesFilters] = useState({});
  const [results, setResults] = useState([]);
  const [activitiesResults, setActivitiesResults] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [activitiesTotalCount, setActivitiesTotalCount] = useState(0);
  const debouncedQuery = useDebounce(searchQuery, 300);
  const [currentTab, setCurrentTab] = useState(options[0]);
  const [loading, setLoading] = useState(true);

  const ids = getIds(destination);
  const getPropertiesData = async ({ selected = 0 }) => {
    setLoading(true);
    const queryData = {
      filters: propertiesFilters,
      query: debouncedQuery,
      offset: paginationLimit * selected,
      limit: paginationLimit,
      ids,
    };

    const { data, pagination } = await getter(queryData);

    setPage(selected);
    setTotalCount(pagination?.totalCount || 0);
    setResults(data);
    setLoading(false);
  };

  const getActivitiesData = async ({ selected = 0 }) => {
    setLoading(true);
    const queryData = {
      filters: activitiesFilters,
      query: debouncedQuery,
      offset: paginationLimit * selected,
      limit: paginationLimit,
      ids,
    };

    const { data: activities, pagination } = await activityGetter(queryData);

    setPage(selected);
    setActivitiesTotalCount(pagination?.totalCount || 0);
    setActivitiesResults(activities);
    setLoading(false);
  };

  useEffect(() => {
    getPropertiesData({ filters: propertiesFilters, query: debouncedQuery });
  }, [currentTab, propertiesFilters, debouncedQuery]);

  useEffect(() => {
    getActivitiesData({ filters: activitiesFilters, query: debouncedQuery });
  }, [currentTab, activitiesFilters, debouncedQuery]);

  const handleProfileChanges = async (values) => {
    const isAuth = isAuthGuard();

    if (!isAuth) {
      storageService.set(LOCALSTORAGE_KEYS.USER, { ...getUser(), searchProfile: values });
      return;
    }

    const { data } = await UsersService().updateSearchProfile(values);

    const {
      // eslint-disable-next-line no-unused-vars
      id, createdAt, updatedAt, ...searchProfile
    } = data;

    storageService.set(LOCALSTORAGE_KEYS.USER, { ...getUser(), searchProfile });
  };

  const locName = getShortDestinationName(
    destination,
    destination.region,
    destination.region?.city,
    destination.region?.city?.district,
  );

  const initValue = {
    name: destination?.name || '',
    locationName: locName || '',
    countryId: destination?.id || 0,
    regionId: destination?.region?.id || 0,
    cityId: destination?.region?.city?.id || 0,
    districtId: destination?.region?.city?.district?.id || 0,
    adults: 2,
    adultGuests: 2,
    kidGuests: 0,
    rooms: 1,
    checkin: new Date(Date.now() + 7 * dayInMilliSeconds),
    checkout: new Date(Date.now() + 9 * dayInMilliSeconds),
  };

  const currentDestination = {
    country: destination,
    region: destination?.region,
    city: destination?.region?.city,
    district: destination?.region?.city?.district,
  };
  storageService.set(STORAGE_DESTINATION, currentDestination);

  const getInitialFilter = useCallback(() => {
    const initialFilter = ACTIVITY_CATEGORY_FILTER_INITIAL_VALUES;
    if (ids.categoriesFilters?.length) {
      const { categoriesFilters } = ids;
      categoriesFilters.forEach((filter) => {
        initialFilter[filter] = true;
      });
    }
    return initialFilter;
  }, [ids.categoriesFilters]);

  return (
    <div className={`${styles.home} flex-grow-1`}>
      <div className={`${styles.container} landing-container flex flex-center`}>
        <div className="col-12 pl-0">

          <div className={styles.homeContent}>
            <ItinerariesTab
              handleSubmitForm={handleProfileChanges}
              setTab={() => setTab(0)}
              initValues={initValue}
              isMarketingPage
            />

            <div>
              <div className={styles.block}>
                <div className={styles.description}>
                  <h1 className={styles.descriptionCentered}>
                    Your trustworthy travel planning app
                  </h1>
                  <p className={styles.textDescription}>
                    We have researched all activities, so
                    you don’t have to. Just enter your
                    search details to generate free
                    itineraries and adjust “Your Interests”
                    settings for more personalized results.
                  </p>
                </div>
                <VideoEmulatorLaptop
                  imgWidth={814}
                  imgHeight={420}
                  videoWidth={650}
                  videoHeight={420}
                  link={desktopVideoLink}
                />
              </div>

              <VideoEmulatorMobile link={mobileVideoLink} />
            </div>

            <div>
              <div className={`flex flex-between w-100 ${styles.searchWrapper}`}>
                <h2 className={`${styles.mainText}`}>
                  <ButtonsGroup
                    options={options}
                    labels={[`${activitiesTotalCount} activities`, `${totalCount} properties`]}
                    setCurrentTab={setCurrentTab}
                    className={styles.buttonsGroup}
                  />
                  in
                  {' '}
                  {getLocationName(destination)}
                </h2>

                <div className={styles.searchBlock}>
                  <SearchInput
                    containerStyles={styles.search}
                    isSimpleSearch
                    placeholder="Search"
                    onChange={(event) => setSearchQuery(event.currentTarget.value)}
                    value={searchQuery}
                    type="text"
                  />
                  <div
                    className={`flex flex-v-center ${styles.filterBlock}`}
                  >
                    <span className={`${styles.mainText} ${styles.filter}`}>
                      Filter
                    </span>
                    <SearchFilters
                      resetButtonText="No filter"
                      onSubmit={(values) => (currentTab === options[0]
                        ? setActivitiesFilters(values) : setPropertiesFilters(values))}
                      initialValues={currentTab === options[0]
                        ? getInitialFilter() : PROPERTY_CATEGORY_FILTER_INITIAL_VALUES}
                      filterOptions={[
                        {
                          name: 'values',
                          options: currentTab === options[0]
                            ? ACTIVITY_CATEGORY_OPTIONS : PROPERTY_CATEGORY_OPTIONS,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.results}>
                {(currentTab === options[0] && !loading) && activitiesResults.map(({
                  id,
                  title,
                  subtitle,
                  address,
                  mainPhotoLink,
                  payingOrFree,
                  city,
                  region,
                  country,
                  urlDestination,
                  urlName,
                  link,
                }) => (
                  <div className={styles.resultsItem} key={id}>
                    <SimpleSearchResultCard
                      onClick={(event) => cardClick(
                        title,
                        city,
                        region,
                        country,
                        urlDestination,
                        urlName,
                        event,
                        true,
                      )}
                      isFree={payingOrFree === free}
                      subtitle={subtitle}
                      title={title}
                      address={address}
                      mainPhotoLink={mainPhotoLink}
                      href={link}
                      city={city?.name || region?.name || country?.name}
                      isMarketing
                      hideChip
                    />
                  </div>
                ))}

                {(currentTab === options[1] && !loading) && results.map(({
                  id,
                  title,
                  subtitle,
                  address,
                  mainPhotoLink,
                  payingOrFree,
                  city,
                  region,
                  country,
                  urlDestination,
                  urlName,
                  link,
                }) => (
                  <div className={styles.resultsItem} key={id}>
                    <SimpleSearchResultCard
                      onClick={(event) => cardClick(
                        title,
                        city,
                        region,
                        country,
                        urlDestination,
                        urlName,
                        event,
                      )}
                      isFree={payingOrFree === free}
                      subtitle={subtitle}
                      title={title}
                      address={address}
                      mainPhotoLink={mainPhotoLink}
                      href={link}
                      city={city?.name || region?.name || country?.name}
                      isMarketing
                      hideChip
                    />
                  </div>
                ))}
              </div>
              <div className={`${styles.pagination} flex flex-center`}>
                <Pagination
                  callback={(v) => getPropertiesData({
                    filters: currentTab === options[0] ? activitiesFilters : propertiesFilters,
                    query: debouncedQuery,
                    ...v,
                  })}
                  total={currentTab === options[0] ? activitiesTotalCount : totalCount}
                  perPage={paginationLimit}
                  forcePage={Number(page)}
                  className={styles.paginationButton}
                  isMarketing
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
